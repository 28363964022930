const data = {
  faqData: {
    // payment
    payment: {
      icon: 'CreditCardIcon',
      title: 'Payment',
      subtitle: 'Payment Questions',
      qandA: [
        {
          question: 'How does payment work?',
          ans:
            'You can choose to be billed via invoices or through AWS Marketplace which is our preferred method. We will extend a private offer with an agreed payment schedule, once confirmed the product will appear in your product list. Once of our consultants will get in touch to customize the solution for you.',
        },
        {
          question: 'Can I get a discount ?',
          ans:
            'All our prices are fixed, however if you are a stratup please reach out we know how hard it is to start a company and we are here to support.',
        },
        {
          question: 'Are there any funding available?',
          ans:
            'Being an AWS partner we have access to various funding mechanisms that we can use to help you with reducing the cost of the engagement.',
        },
        {
          question: 'Can I re-distribute a product I\'ve purchased?',
          ans:
            'Under our licence agreement you cant re-destribute or resell our intellectual property.',
        },
      ],
    },
    // delivery
    delivery: {
      icon: 'ShoppingBagIcon',
      title: 'Delivery',
      subtitle: 'What does the delivery look like?',
      qandA: [
        {
          question: 'Where has my order reached?',
          ans:
            'If you have made a purchase and havent heard back from us please get in touch we are working on making this process more transparent. Stay tuned!',
        },
        {
          question:
            'What is the next step after the product appears in my product section?',
          ans:
            'Once the product apprears in your product section (you will recieve an email from us), you will have everything you need to deploy the solution. However if your product comes with professional support we will get in touch with you to schedule a time to complete the project. You can also click on your product and under help book some time with one of our consultants.',
        },
        {
          question: 'How long does the delivery take?',
          ans:
            'This could vary depending on the scope of the project, but most of the solutions will take roughly around 1 day to implement as we have an extensive library of components that we can use.',
        },
        {
          question: 'Can we use internal project management tools to track the project?',
          ans:
            'Yes, for bigger projects we will are happy to use your tools or ours to track the progress of the project.',
        }
      ],
    },
    // cancellation and return
    cancellationReturn: {
      icon: 'RefreshCwIcon',
      title: 'Cancellation & Return',
      subtitle: 'What is your return policy?',
      qandA: [
        {
          question: 'What is your return policy?',
          ans:
            'We stand by our products, by purchasing through automatum we will give you a 10 business day gurantee. This means if there is any issues with the solution we will work with you to resolve the issue.',
        },
        {
          question: 'How can I get in touch if I have an issue?',
          ans:
            'You can use the Calenderly link at the bottom of this page or email us.',
        },
        {
          question: 'I bought the wrong product how can I get a refunt?',
          ans:
          'You can use the Calenderly link at the bottom of this page or email us.',
        },
      ],
    },
    // my orders
    // myOrders: {
    //   icon: 'PackageIcon',
    //   title: 'My Orders',
    //   subtitle: 'Which license do I need?',
    //   qandA: [
    //     {
    //       question: 'Can I avail of an open delivery?',
    //       ans:
    //         'Pastry pudding cookie toffee bonbon jujubes jujubes powder topping. Jelly beans gummi bears sweet roll bonbon muffin liquorice. Wafer lollipop sesame snaps. Brownie macaroon cookie muffin cupcake candy caramels tiramisu. Oat cake chocolate cake sweet jelly-o brownie biscuit marzipan. Jujubes donut marzipan chocolate bar. Jujubes sugar plum jelly beans tiramisu icing cheesecake.',
    //     },
    //     {
    //       question: 'I haven’t received the refund of my returned shipment. What do I do?',
    //       ans:
    //         'Sweet pie candy jelly. Sesame snaps biscuit sugar plum. Sweet roll topping fruitcake. Caramels liquorice biscuit ice cream fruitcake cotton candy tart. Donut caramels gingerbread jelly-o gingerbread pudding. Gummi bears pastry marshmallow candy canes pie. Pie apple pie carrot cake.',
    //     },
    //     {
    //       question: 'How can I ship my order to an international location?',
    //       ans:
    //         'Tart gummies dragée lollipop fruitcake pastry oat cake. Cookie jelly jelly macaroon icing jelly beans soufflé cake sweet. Macaroon sesame snaps cheesecake tart cake sugar plum. Dessert jelly-o sweet muffin chocolate candy pie tootsie roll marzipan.',
    //     },
    //     {
    //       question: 'I missed the delivery of my order today. What should I do?',
    //       ans:
    //         'Cheesecake muffin cupcake dragée lemon drops tiramisu cake gummies chocolate cake. Marshmallow tart croissant. Tart dessert tiramisu marzipan lollipop lemon drops. Cake bonbon bonbon gummi bears topping jelly beans brownie jujubes muffin. Donut croissant jelly-o cake marzipan. Liquorice marzipan cookie wafer tootsie roll. Tootsie roll sweet cupcake.',
    //     },
    //     {
    //       question: 'The delivery of my order is delayed. What should I do?',
    //       ans:
    //         'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur.',
    //     },
    //   ],
    // },
    // product and services
    productServices: {
      icon: 'SettingsIcon',
      title: 'Product & Services',
      subtitle: 'Which services do you offer?',
      qandA: [
        {
          question: 'Which services do you offer?',
          ans:
            'Automatumcloud is a product offering by Automatum we are a consultacny firm that aims to automate workflows using Cloud Technologies.',
        },
        {
          question: 'I cant find the product I am looking for?',
          ans:
            'If you cant find the product you need please reach out to us and we will implement it for you.',
        },
      ],
    },
  },
}
export { data }